import React, { useEffect, useRef, useState } from "react";
import { Button, Descriptions, Form, Modal } from "antd";
import { getSelectedSheet } from "../../helpers/storeManager";
import dayjs from "dayjs";
import { writeExcelFile } from "../../helpers/writeExcelFile";
import FirstDayShifts from "../firstDayShifts/FirstDayShifts";
import EditSheetModal from "../modals/EditSheetModal";

const SheetBody = ({ selectedMenu }) => {
  const formRef = useRef();
  const [validationError, setValidationError] = useState("");
  const [sheetDetails, setSheetDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  useEffect(() => {
    setSheetDetails(getSelectedSheet(selectedMenu));
  }, [selectedMenu]);

  const handleOpenModal = (date) => {
    setIsModalOpen(true);
    setSelectedDate(date);
  };

  const handleWriteFile = () => {
    formRef.current.validateFields().then((res) => {
      let hasMap = {};

      for (let key in res) {
        if (hasMap[res[key]]) {
          setValidationError("Duplicate employee selected");
          return;
        }

        hasMap[res[key]] = true;
      }

      setValidationError("");
      writeExcelFile(selectedDate, sheetDetails, res);
    });
  };

  return (
    <div>
      <Descriptions
        bordered
        title={`${sheetDetails.name} (PHASE : ${sheetDetails.phase || ""})`}
        size={"middle"}
        extra={<EditSheetModal sheet={sheetDetails} selectedKey={selectedMenu} />}
      >
        <Descriptions.Item label='Saturday' span={2}>
          <b>Name: </b> {sheetDetails.saturday} <br />
          <b>Phone: </b> {sheetDetails.saturdayPhone} <br />
          <b>Code: </b> {sheetDetails.saturdayCode}
        </Descriptions.Item>

        <Descriptions.Item label='Shift 1'>{sheetDetails.shift1}</Descriptions.Item>
        <Descriptions.Item label='Sunday' span={2}>
          <b>Name: </b> {sheetDetails.sunday} <br />
          <b>Phone: </b> {sheetDetails.sundayPhone} <br />
          <b>Code: </b> {sheetDetails.sundayCode}
        </Descriptions.Item>

        <Descriptions.Item label='Shift 2'>{sheetDetails.shift2}</Descriptions.Item>
        <Descriptions.Item label='Monday' span={2}>
          <b>Name: </b> {sheetDetails.monday} <br />
          <b>Phone: </b> {sheetDetails.mondayPhone} <br />
          <b>Code: </b> {sheetDetails.mondayCode}
        </Descriptions.Item>
        <Descriptions.Item label='Shift 3'>{sheetDetails.shift3}</Descriptions.Item>

        <Descriptions.Item label='Tuesday' span={3}>
          <b>Name: </b> {sheetDetails.tuesday} <br />
          <b>Phone: </b> {sheetDetails.tuesdayPhone} <br />
          <b>Code: </b> {sheetDetails.tuesdayCode}
        </Descriptions.Item>

        <Descriptions.Item label='Wednesday' span={3}>
          <b>Name: </b> {sheetDetails.wednesday} <br />
          <b>Phone: </b> {sheetDetails.wednesdayPhone} <br />
          <b>Code: </b> {sheetDetails.wednesdayCode}
        </Descriptions.Item>

        <Descriptions.Item label='Thursday' span={3}>
          <b>Name: </b> {sheetDetails.thursday} <br />
          <b>Phone: </b> {sheetDetails.thursdayPhone} <br />
          <b>Code: </b> {sheetDetails.thursdayCode}
        </Descriptions.Item>

        <Descriptions.Item label='Friday' span={3}>
          <b>Name: </b> {sheetDetails.friday} <br />
          <b>Phone: </b> {sheetDetails.fridayPhone} <br />
          <b>Code: </b> {sheetDetails.fridayCode}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <br />
      <Descriptions title='Download Sheet' size={"middle"}>
        <Descriptions.Item label={`This month (${dayjs().format("MMM YYYY")})`} labelStyle={{ alignItems: "center" }}>
          <Button type='primary' onClick={() => handleOpenModal(dayjs())}>
            Download this month
          </Button>
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          label={`Previous month (${dayjs().subtract(1, "month").format("MMM YYYY")})`}
          labelStyle={{ alignItems: "center" }}
        >
          <Button type='primary' onClick={() => handleOpenModal(dayjs().subtract(1, "month"))}>
            Download previous month
          </Button>
        </Descriptions.Item>
        <br />
        <br />
        <br />

        <Descriptions.Item
          span={3}
          label={`Next month (${dayjs().add(1, "month").format("MMM YYYY")})`}
          labelStyle={{ alignItems: "center" }}
        >
          <Button type='primary' onClick={() => handleOpenModal(dayjs().add(1, "month"))}>
            Download next month
          </Button>
        </Descriptions.Item>

        <Descriptions.Item
          span={3}
          label={`Afterward months`}
          labelStyle={{ alignItems: "center" }}
          contentStyle={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px" }}
        >
          <Button type='primary' onClick={() => handleOpenModal(dayjs().add(2, "month"))}>
            Download ({dayjs().add(2, "month").format("MMM-YYYY")}) data
          </Button>
          <Button type='primary' onClick={() => handleOpenModal(dayjs().add(3, "month"))}>
            Download ({dayjs().add(3, "month").format("MMM-YYYY")}) data
          </Button>
          <Button type='primary' onClick={() => handleOpenModal(dayjs().add(4, "month"))}>
            Download ({dayjs().add(4, "month").format("MMM-YYYY")}) data
          </Button>
          <Button type='primary' onClick={() => handleOpenModal(dayjs().add(5, "month"))}>
            Download ({dayjs().add(5, "month").format("MMM-YYYY")}) data
          </Button>
          <Button type='primary' onClick={() => handleOpenModal(dayjs().add(6, "month"))}>
            Download ({dayjs().add(6, "month").format("MMM-YYYY")}) data
          </Button>
        </Descriptions.Item>
      </Descriptions>
      <Modal
        destroyOnClose
        title={`Fill up 1st day shifts of ${selectedDate.format("MMMM")}`}
        open={isModalOpen}
        width={800}
        onOk={handleWriteFile}
        okText='Download sheet'
        onCancel={() => {
          setIsModalOpen(false);
          setValidationError("");
        }}
        cancelButtonProps={{ id: "createSheetCancelButton" }}
      >
        <Form
          ref={formRef}
          layout='vertical'
          labelCol={{ span: 15 }}
          wrapperCol={{ span: 16 }}
          style={{ minWidth: 600 }}
          autoComplete='off'
        >
          <FirstDayShifts
            data={sheetDetails}
            firstDay={selectedDate.startOf("month").format("dddd").toLowerCase()}
            validationError={validationError}
          />
        </Form>
      </Modal>
    </div>
  );
};
export default SheetBody;
