import dayjs from "dayjs";
import { utils } from "xlsx";
import { addStyle } from "./addStyle";
import { getBlankInitValue } from "./excelHelpers/getBlankInitValue";
import { putShifts } from "./excelHelpers/putShifts";
import { updateRemarks } from "./excelHelpers/updateRemarks";
import { workbook2blob } from "./excelHelpers/workbook2blob";
import { writeStaticData } from "./excelHelpers/writeStaticData";



const handleExport = (date, sheetData = {}, firstDayData = {}) => {
  let finalData = getBlankInitValue();

  //Writing basic data
  writeStaticData(finalData, sheetData, date);

  //Fillup dates
  const totalDaysInMonth = dayjs(date).endOf("month").date();
  const startDate = dayjs(date).startOf("month");

  for (let i = 0; i < totalDaysInMonth; i++) {
    finalData[4 + i] = { ...finalData[4 + i], B: startDate.add(i, "day").format("DD/MMMM,YYYY") };
  }

  //Fillup remarks
  updateRemarks(finalData, sheetData);

  //Fillup shifts
  putShifts(finalData, sheetData, date, firstDayData);

  //create a new workbook
  const wb = utils.book_new();

  const sheet = utils.json_to_sheet(finalData, {
    skipHeader: true,
  });

  utils.book_append_sheet(wb, sheet, "sheet 1");

  // binary large object
  // Since blobs can store binary data, they can be used to store images or other multimedia files.
  const workbookBlob = workbook2blob(wb);

  const dataInfo = {
    borderCells: [
      "B2:L2",
      `B3:H${4 + totalDaysInMonth}`,
      `J3:L11`,
      "N4:O6",
      `B${6 + totalDaysInMonth}:H${10 + totalDaysInMonth}`,
      `B${totalDaysInMonth + 12}:H${totalDaysInMonth + 12}`,
    ],
    titleRange: "B2:L2",
    shiftHeadRange: [
      "B3:B3",
      "C3:D3",
      "E3:F3",
      "G3:H3",
      "J3:L3",
      `B${totalDaysInMonth + 15}:C${totalDaysInMonth + 15}`,
      `J${totalDaysInMonth + 15}:L${totalDaysInMonth + 15}`,
    ],
    timesNewRomanColumns11: ["J3:L3", "N4:O6", "J4:L11"],
    shiftTimesRange: ["C4:D4", "E4:F4", "G4:H4", `B${totalDaysInMonth + 12}:H${totalDaysInMonth + 12}`],
    remarksHead: "J4:L4",
    infoHead: "N4:N6",
    signatureRow: [totalDaysInMonth + 14],
    signatureBoxes: [
      `B${totalDaysInMonth + 14}:C${totalDaysInMonth + 14}`,
      `J${totalDaysInMonth + 14}:L${totalDaysInMonth + 14}`,
    ],
  };

  for (let bottomRows = totalDaysInMonth + 5; bottomRows <= totalDaysInMonth + 10; bottomRows++) {
    dataInfo.shiftHeadRange.push(`B${bottomRows}:B${bottomRows}`);
    dataInfo.shiftHeadRange.push(`C${bottomRows}:C${bottomRows}`);
    dataInfo.shiftHeadRange.push(`D${bottomRows}:D${bottomRows}`);
    dataInfo.shiftHeadRange.push(`E${bottomRows}:E${bottomRows}`);
    dataInfo.shiftHeadRange.push(`F${bottomRows}:F${bottomRows}`);
    dataInfo.shiftHeadRange.push(`G${bottomRows}:H${bottomRows}`);
  }

  return addStyle(workbookBlob, dataInfo);
};

export const writeExcelFile = (date, sheetData, firstDayData) => {
  handleExport(date, sheetData, firstDayData).then((url) => {
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", url);
    downloadAnchorNode.setAttribute(
      "download",
      `Duty Schedule ${dayjs(date).format("MMMM-YY")}(${sheetData.name}).xlsx`
    );
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  });
};
