import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;

const PageFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
      }}
    >
      Shift Time Generator ©{(new Date()).getFullYear()} Created by{" "}
      <a href='https://riyaddecoder.github.io/me' target='_blank' rel='noreferrer'>
        Shahidul Alam Riyad
      </a>
    </Footer>
  );
};

export default PageFooter;
