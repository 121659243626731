import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Cambria",
          verticalAlignment: "center",
        });
  
        sheet.row(1).height(8.125);
        sheet.row(dataInfo.signatureRow).height(30);
        sheet.column("A").width(0.75);
        sheet.column("B").width(17);
        sheet.column("I").width(0.75);
        sheet.column("J").width(10);
        sheet.column("K").width(12);
        sheet.column("L").width(15);
        sheet.column("M").width(4.3);
        sheet.column("O").width(12);
  
        dataInfo.borderCells.forEach((eachRange) => {
          sheet.range(eachRange).style({
            border: "thin",
            borderColor: "000000",
          });
        });
  
        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fill: "EBF1DE",
          fontColor: "C00000",
          fontSize: 12,
        });
  
        dataInfo.shiftHeadRange.forEach((eachRange) => {
          sheet.range(eachRange).merged(true).style({
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });
        });
  
        dataInfo.timesNewRomanColumns11.forEach((eachRange) => {
          sheet.range(eachRange).style({
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center",
            fontFamily: "Times New Roman",
          });
        });
  
        dataInfo.shiftTimesRange.forEach((eachRange) => {
          sheet.range(eachRange).merged(true).style({
            fill: "EBF1DE",
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center",
            fontSize: 10,
          });
        });
  
        sheet.range(dataInfo.remarksHead).style({
          fill: "DCE6F2",
        });
  
        sheet.range(dataInfo.infoHead).style({
          fill: "EEECE1",
        });
  
        dataInfo.signatureBoxes.forEach((eachRange) => {
          sheet
            .range(eachRange)
            .merged(true)
            .style({
              border: {
                bottom: { style: "medium", color: { rgb: "000000" } },
              },
              borderColor: "000000",
            });
        });
      });
  
      return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };