import React from "react";
import { Layout, theme } from "antd";
import CreateSheetModal from "../modals/CreateSheetModal";
const { Header } = Layout;

const PageHeader = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
      }}
    >
      <div style={{ marginLeft: 20 }}>
        <CreateSheetModal />
      </div>
    </Header>
  );
};

export default PageHeader;
