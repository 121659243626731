import React from "react";
import { Col, Form, Row, Select, Typography } from "antd";

const { Text } = Typography;

const FirstDayShifts = ({ data, validationError, firstDay }) => {
  const getSelectData = () => {
    return [
      { value: "saturday", label: data.saturday },
      { value: "sunday", label: data.sunday },
      { value: "monday", label: data.monday },
      { value: "tuesday", label: data.tuesday },
      { value: "wednesday", label: data.wednesday },
      { value: "thursday", label: data.thursday },
      { value: "friday", label: data.friday },
    ].filter((eachDay) => eachDay.value !== firstDay);
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            label='Shift A1'
            name='shiftA1'
            rules={[{ required: true, message: "Please input A1 shift time" }]}
          >
            <Select placeholder='Please select employee' options={getSelectData()} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label='Shift A2'
            name='shiftA2'
            rules={[{ required: true, message: "Please input A2 shift time" }]}
          >
            <Select placeholder='Please select employee' options={getSelectData()} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            label='Shift B1'
            name='shiftB1'
            rules={[{ required: true, message: "Please input B1 shift time" }]}
          >
            <Select placeholder='Please select employee' options={getSelectData()} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Shift B2'
            name='shiftB2'
            rules={[{ required: true, message: "Please input B2 shift time" }]}
          >
            <Select placeholder='Please select employee' options={getSelectData()} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            label='Shift C1'
            name='shiftC1'
            rules={[{ required: true, message: "Please input C1 shift time" }]}
          >
            <Select placeholder='Please select employee' options={getSelectData()} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Shift C2'
            name='shiftC2'
            rules={[{ required: true, message: "Please input C2 shift time" }]}
          >
            <Select placeholder='Please select employee' options={getSelectData()} />
          </Form.Item>
        </Col>
      </Row>

      <Text type='danger' strong>
        {validationError}
      </Text>
    </>
  );
};

export default FirstDayShifts;
