import { nanoid } from "nanoid";

const ALL_ITEM_KEY = "allSheets";

export const getAllSheets = () => {
  let allItems = localStorage.getItem(ALL_ITEM_KEY);
  if (allItems === null) {
    return [];
  }

  return JSON.parse(allItems);
};

export const storeNewSheet = (formData) => {
  let allSheets = getAllSheets();
  let newKey = nanoid();

  //Updating and setting left list
  allSheets.push({ name: formData.name, key: newKey });
  localStorage.setItem(ALL_ITEM_KEY, JSON.stringify(allSheets));

  //Setting the new form data
  localStorage.setItem(newKey, JSON.stringify(formData));

  return newKey;
};

export const updateSheet = (formData, key) => {
  let allSheets = getAllSheets();

  //Updating the sheet name for list view the name.
  allSheets.find((each) => each.key === key).name = formData.name;
  localStorage.setItem(ALL_ITEM_KEY, JSON.stringify(allSheets));

  //Setting the new form data
  localStorage.setItem(key, JSON.stringify(formData));
};

export const deleteSheet = (sheetKey) => {
  let allSheets = getAllSheets();
  allSheets = allSheets.filter((obj) => obj.key !== sheetKey);
  localStorage.setItem(ALL_ITEM_KEY, JSON.stringify(allSheets));
  localStorage.removeItem(sheetKey);
};

export const getSelectedSheet = (key) => {
  let selectedItem = localStorage.getItem(key);
  return selectedItem === null ? null : JSON.parse(selectedItem);
};
