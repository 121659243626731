import React from "react";
import { Form, Input } from "antd";

const SheetName = () => {
  return (
    <>
      <Form.Item
        label='Sheet/Team Name'
        name='name'
        rules={[{ required: true, message: "Please input the sheet name" }]}
      >
        <Input placeholder="Input a name here"/>
      </Form.Item>

      <Form.Item
        label='Shift Number'
        name='phase'
        rules={[{ required: true, message: "Please input the phase number" }]}
      >
        <Input placeholder="SECOND, THIRD"/>
      </Form.Item>
    </>
  );
};

export default SheetName;
