import React, { createContext, useEffect, useState } from "react";
import { Layout } from "antd";
import Sidebar from "./layout/Sidebar";
import PageHeader from "./layout/PageHeader";
import PageBody from "./layout/PageBody";
import PageFooter from "./layout/PageFooter";
import { getAllSheets } from "../helpers/storeManager";

export const SheetContext = createContext({
  onAddItem: () => {},
});

const App = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleListRefresh = (newItem) => {
    let allItems = getAllSheets();
    setItems(allItems);
    setSelectedItem(newItem);
    setRefreshKey(refreshKey + 1);
  };

  useEffect(() => {
    let allItems = getAllSheets();
    setItems(allItems);
    setSelectedItem(allItems[0]?.key);
  }, []);

  return (
    <SheetContext.Provider value={{ onAddItem: handleListRefresh }}>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sidebar
          items={items}
          selectedMenu={selectedItem}
          onChangeMenu={(menu) => setSelectedItem(menu)}
          onDeleteMenu={handleListRefresh}
        />
        <Layout className='site-layout'>
          <PageHeader />
          <PageBody selectedMenu={selectedItem} key={refreshKey} />
          <PageFooter />
        </Layout>
      </Layout>
    </SheetContext.Provider>
  );
};

export default App;
