import React, { useState } from "react";
import { Col, Layout, Menu, Popconfirm, Row } from "antd";
import { getMenuItem } from "../../helpers/getMenuItem";
import { FileOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteSheet } from "../../helpers/storeManager";
const { Sider } = Layout;

const Sidebar = ({ items, selectedMenu, onChangeMenu = () => {}, onDeleteMenu = () => {} }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleDeleteSheet = (menuKey) => {
    deleteSheet(menuKey);
    onDeleteMenu(menuKey === selectedMenu ? null : selectedMenu);
  };

  const renderMenuItems = () =>
    items.map((eachItem) =>
      getMenuItem(
        <Row>
          <Col span={18}>{eachItem.name}</Col>
          <Col span={6} onClick={(e) => e.currentTarget !== e.target && e.stopPropagation()}>
            <Popconfirm
              placement='right'
              title={"Are you sure to delete this sheet?"}
              description={"This sheet will be deleted"}
              onConfirm={() => handleDeleteSheet(eachItem.key)}
              okText='Yes Delete!'
              cancelText='No'
            >
              <DeleteOutlined />
            </Popconfirm>
          </Col>
        </Row>,
        eachItem.key,
        <FileOutlined />
      )
    );

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div
        style={{
          height: 32,
          margin: 16,
          background: "rgba(255, 255, 255, 0.2)",
        }}
      />
      <Menu
        theme='dark'
        selectedKeys={[selectedMenu]}
        mode='inline'
        items={renderMenuItems()}
        onClick={(e) => {
          onChangeMenu(e.key);
        }}
      />
    </Sider>
  );
};

export default Sidebar;
