import dayjs from "dayjs";

export const writeStaticData = (arr, sheetData, date) => {
  const dayJsObj = dayjs(date);
  const totalNumberOfDays = dayjs(date).endOf("month").date();
  arr[1].B = `DUTY SCHEDULE OF KSI ${(
    sheetData.phase || ""
  ).toUpperCase()} PHASE SUB-STATION FOR THE MONTH OF ${dayJsObj.format("MMMM-YYYY").toUpperCase()}.`;

  arr[2] = { ...arr[2], C: "A-Shift", E: "B-Shift", G: "C-Shift", J: "Remarks" };

  arr[3] = {
    ...arr[3],
    B: "Date",
    C: sheetData.shift1,
    E: sheetData.shift2,
    G: sheetData.shift3,
    J: "Name",
    K: "Rest Day",
    L: "Mobile No",
    N: "Month",
    O: dayJsObj.format("MMMM").toUpperCase(),
  };

  arr[4] = {
    ...arr[4],
    N: "YEAR",
    O: dayJsObj.format("YYYY"),
  };

  arr[5] = {
    ...arr[4],
    N: "PHASE",
    O: sheetData.phase || "",
  };

  arr[totalNumberOfDays + 5] = {
    ...arr[totalNumberOfDays + 5],
    B: "SL",
    C: "Name",
    D: "Code No",
    E: "SL",
    F: "Name",
    G: "Code No",
  };

  arr[totalNumberOfDays + 6] = {
    ...arr[totalNumberOfDays + 6],
    B: "1",
    C: sheetData.saturday,
    D: sheetData.saturdayCode,
    E: "5",
    F: sheetData.wednesday,
    G: sheetData.wednesdayCode,
  };

  arr[totalNumberOfDays + 7] = {
    ...arr[totalNumberOfDays + 7],
    B: "2",
    C: sheetData.sunday,
    D: sheetData.sundayCode,
    E: "6",
    F: sheetData.thursday,
    G: sheetData.thursdayCode,
  };

  arr[totalNumberOfDays + 8] = {
    ...arr[totalNumberOfDays + 8],
    B: "3",
    C: sheetData.monday,
    D: sheetData.mondayCode,
    E: "7",
    F: sheetData.friday,
    G: sheetData.fridayCode,
  };

  arr[totalNumberOfDays + 9] = {
    ...arr[totalNumberOfDays + 9],
    B: "4",
    C: sheetData.tuesday,
    D: sheetData.tuesdayCode,
  };

  arr[totalNumberOfDays + 11] = {
    ...arr[totalNumberOfDays + 11],
    B: " NB: The Roster will be Changed If any emergency requirement.",
  };

  arr[totalNumberOfDays + 14] = {
    ...arr[totalNumberOfDays + 14],
    B: "Prepared by",
    J: "Approved by",
  };
};
