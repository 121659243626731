import React, { useContext, useRef, useState } from "react";
import { Button, Form, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import SheetName from "../shiftSetup/SheetName";
import ShiftMembers from "../shiftSetup/ShiftMembers";
import ShiftTime from "../shiftSetup/ShiftTime";
import { updateSheet } from "../../helpers/storeManager";
import { SheetContext } from "../App";

const SHIFTS = {
  NAME: 0,
  TIME: 1,
  MEMBERS: 2,
};

const TOTAL_SHIFTS = 3;

const EditSheetModal = ({ sheet, selectedKey }) => {
  const formRef = useRef();
  const { onAddItem } = useContext(SheetContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [formData, setFormData] = useState({});

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    formRef.current.validateFields().then((res) => {
      let nextPage = currentPage + 1;
      let newFormData = { ...formData, ...res };
      setFormData(newFormData);
      setCurrentPage(nextPage);

      if (nextPage >= 3) {
        updateSheet(newFormData, selectedKey);
        onAddItem(selectedKey);
        setIsModalOpen(false);
        setCurrentPage(0);
      }
    });
  };

  const handleCancel = (e) => {
    if (e.currentTarget.id === "createSheetCancelButton") {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
        return;
      }
    }

    setCurrentPage(0);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type='primary' size='large' icon={<EditOutlined />} onClick={showModal}>
        Edit This Sheet
      </Button>
      <Modal
        destroyOnClose
        title='Edit Sheet'
        open={isModalOpen}
        width={800}
        onOk={handleOk}
        okText={currentPage + 1 < TOTAL_SHIFTS ? "Next" : "Save"}
        onCancel={handleCancel}
        cancelText={currentPage === 0 ? "Cancel" : "Back"}
        cancelButtonProps={{ id: "createSheetCancelButton" }}
      >
        <Form
          ref={formRef}
          layout='vertical'
          labelCol={{ span: 15 }}
          wrapperCol={{ span: 16 }}
          style={{ minWidth: 600 }}
          autoComplete='off'
          initialValues={sheet}
        >
          {currentPage === SHIFTS.NAME ? <SheetName /> : currentPage === SHIFTS.TIME ? <ShiftTime /> : <ShiftMembers />}
        </Form>
      </Modal>
    </>
  );
};

export default EditSheetModal;
