import dayjs from "dayjs";

export const putShifts = (arr, sheetData, date, firstDayData) => {
  const totalDaysInMonth = dayjs(date).endOf("month").date();
  const startDate = dayjs(date).startOf("month");

  for (let rowIndex = 4; rowIndex <= 4 + totalDaysInMonth; rowIndex++) {
    let currentDate = startDate.add(rowIndex - 4, "day");
    if (!arr[rowIndex].C) {
      let i = 0;
      if (rowIndex !== 4) {
        firstDayData.shiftA1 = currentDate.subtract(1, "day").format("dddd").toLowerCase();
      }
      while (firstDayData.shiftA1 !== currentDate.add(i, "day").format("dddd").toLowerCase()) {
        if (rowIndex - 4 + i >= totalDaysInMonth) {
          break;
        }
        arr[rowIndex + i].C = sheetData[firstDayData.shiftA1];
        i++;
      }
    }

    if (!arr[rowIndex].D) {
      let i = 0;
      if (rowIndex !== 4) {
        firstDayData.shiftA2 = currentDate.subtract(1, "day").format("dddd").toLowerCase();
      }
      while (firstDayData.shiftA2 !== currentDate.add(i, "day").format("dddd").toLowerCase()) {
        if (rowIndex - 4 + i >= totalDaysInMonth) {
          break;
        }
        arr[rowIndex + i].D = sheetData[firstDayData.shiftA2];
        i++;
      }
    }

    if (!arr[rowIndex].E) {
      let i = 0;
      if (rowIndex !== 4) {
        firstDayData.shiftB1 = currentDate.subtract(1, "day").format("dddd").toLowerCase();
      }
      while (firstDayData.shiftB1 !== currentDate.add(i, "day").format("dddd").toLowerCase()) {
        if (rowIndex - 4 + i >= totalDaysInMonth) {
          break;
        }
        arr[rowIndex + i].E = sheetData[firstDayData.shiftB1];
        i++;
      }
    }

    if (!arr[rowIndex].F) {
      let i = 0;
      if (rowIndex !== 4) {
        firstDayData.shiftB2 = currentDate.subtract(1, "day").format("dddd").toLowerCase();
      }
      while (firstDayData.shiftB2 !== currentDate.add(i, "day").format("dddd").toLowerCase()) {
        if (rowIndex - 4 + i >= totalDaysInMonth) {
          break;
        }
        arr[rowIndex + i].F = sheetData[firstDayData.shiftB2];
        i++;
      }
    }

    if (!arr[rowIndex].G) {
      let i = 0;
      if (rowIndex !== 4) {
        firstDayData.shiftC1 = currentDate.subtract(1, "day").format("dddd").toLowerCase();
      }
      while (firstDayData.shiftC1 !== currentDate.add(i, "day").format("dddd").toLowerCase()) {
        if (rowIndex - 4 + i >= totalDaysInMonth) {
          break;
        }
        arr[rowIndex + i].G = sheetData[firstDayData.shiftC1];
        i++;
      }
    }

    if (!arr[rowIndex].H) {
      let i = 0;
      if (rowIndex !== 4) {
        firstDayData.shiftC2 = currentDate.subtract(1, "day").format("dddd").toLowerCase();
      }
      while (firstDayData.shiftC2 !== currentDate.add(i, "day").format("dddd").toLowerCase()) {
        if (rowIndex - 4 + i >= totalDaysInMonth) {
          break;
        }
        arr[rowIndex + i].H = sheetData[firstDayData.shiftC2];
        i++;
      }
    }
  }

  // for (let rowIndex = 4; rowIndex <= totalDaysInMonth + 5; rowIndex++) {
  //   if (!arr[rowIndex].C) {
  //     let i = 0;
  //     while (firstDayData.shiftA1 !== startDate.add(i++, "day").format("dddd").toLowerCase()) {
  //       arr[rowIndex].C = sheetData[startDate.add(i++, "day").format("dddd").toLowerCase()];
  //     }
  //   }
  // }
};
