import React from "react";
import { Empty, Layout, theme } from "antd";
import SheetBody from "../sheetBody/SheetBody";
const { Content } = Layout;

const PageBody = ({ selectedMenu }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <div
        style={{
          marginTop: 17,
          padding: 24,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        {selectedMenu ? <SheetBody selectedMenu={selectedMenu} /> : <Empty />}
      </div>
    </Content>
  );
};

export default PageBody;
