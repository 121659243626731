import React from "react";
import { Form, Input } from "antd";

const ShiftTime = () => {
  return (
    <>
      <Form.Item label='Shift A' name='shift1' rules={[{ required: true, message: "Please input A shift time" }]}>
        <Input/>
      </Form.Item>

      <Form.Item label='Shift B' name='shift2' rules={[{ required: true, message: "Please input B shift time" }]}>
        <Input/>
      </Form.Item>

      <Form.Item label='Shift C' name='shift3' rules={[{ required: true, message: "Please input C shift time" }]}>
        <Input/>
      </Form.Item>
    </>
  );
};

export default ShiftTime;
