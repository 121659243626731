import React from "react";
import { Form, Input } from "antd";

const ShiftMembers = () => {
  return (
    <>
      <Form.Item
        label='Employee Details (Saturday rest person)'
        name='saturday'
        rules={[{ required: true, message: "Please input the employee name" }]}
      >
        <Input placeholder='Enter name' />
      </Form.Item>
      <Form.Item name='saturdayPhone' rules={[{ required: true, message: "Please input the employee phone" }]}>
        <Input placeholder='Enter phone' />
      </Form.Item>
      <Form.Item name='saturdayCode'>
        <Input placeholder='Enter code' />
      </Form.Item>

      <Form.Item
        label='Employee Details (Sunday rest person)'
        name='sunday'
        rules={[{ required: true, message: "Please input the employee name" }]}
      >
        <Input placeholder='Enter name' />
      </Form.Item>
      <Form.Item name='sundayPhone' rules={[{ required: true, message: "Please input the employee phone" }]}>
        <Input placeholder='Enter phone' />
      </Form.Item>
      <Form.Item name='sundayCode'>
        <Input placeholder='Enter code' />
      </Form.Item>

      <Form.Item
        label='Employee Details (Monday rest person)'
        name='monday'
        rules={[{ required: true, message: "Please input the employee name" }]}
      >
        <Input placeholder='Enter name' />
      </Form.Item>
      <Form.Item name='mondayPhone' rules={[{ required: true, message: "Please input the employee phone" }]}>
        <Input placeholder='Enter phone' />
      </Form.Item>
      <Form.Item name='mondayCode'>
        <Input placeholder='Enter code' />
      </Form.Item>

      <Form.Item
        label='Employee Details (Tuesday rest person)'
        name='tuesday'
        rules={[{ required: true, message: "Please input the employee name" }]}
      >
        <Input placeholder='Enter name' />
      </Form.Item>
      <Form.Item name='tuesdayPhone' rules={[{ required: true, message: "Please input the employee phone" }]}>
        <Input placeholder='Enter phone' />
      </Form.Item>
      <Form.Item name='tuesdayCode'>
        <Input placeholder='Enter code' />
      </Form.Item>

      <Form.Item
        label='Employee Details (Wednesday rest person)'
        name='wednesday'
        rules={[{ required: true, message: "Please input the employee name" }]}
      >
        <Input placeholder='Enter name' />
      </Form.Item>
      <Form.Item name='wednesdayPhone' rules={[{ required: true, message: "Please input the employee phone" }]}>
        <Input placeholder='Enter phone' />
      </Form.Item>
      <Form.Item name='wednesdayCode'>
        <Input placeholder='Enter code' />
      </Form.Item>

      <Form.Item
        label='Employee Details (Thursday rest person)'
        name='thursday'
        rules={[{ required: true, message: "Please input the employee name" }]}
      >
        <Input placeholder='Enter name' />
      </Form.Item>
      <Form.Item name='thursdayPhone' rules={[{ required: true, message: "Please input the employee phone" }]}>
        <Input placeholder='Enter phone' />
      </Form.Item>
      <Form.Item name='thursdayCode'>
        <Input placeholder='Enter code' />
      </Form.Item>

      <Form.Item
        label='Employee Details (Friday rest person)'
        name='friday'
        rules={[{ required: true, message: "Please input the employee name" }]}
      >
        <Input placeholder='Enter name' />
      </Form.Item>
      <Form.Item name='fridayPhone' rules={[{ required: true, message: "Please input the employee phone" }]}>
        <Input placeholder='Enter phone' />
      </Form.Item>
      <Form.Item name='fridayCode'>
        <Input placeholder='Enter code' />
      </Form.Item>
    </>
  );
};

export default ShiftMembers;
