export const getBlankInitValue = () => {
  let emptyArr = [];
  let defaultObj = {
    A: "",
    B: "",
    C: "",
    D: "",
    E: "",
    F: "",
    G: "",
    H: "",
    I: "",
    J: "",
    K: "",
    L: "",
    M: "",
    N: "",
    O: "",
  };
  for (let i = 0; i < 50; i++) {
    emptyArr.push({ ...defaultObj });
  }

  return emptyArr;
};
